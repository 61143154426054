import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  data: {
    marginBottom: theme.spacing(8),
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function PaginationWrapper({ data, perPage, render }) {
  const classes = useStyles();
  const theme = useTheme();
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.only("xs"));

  const totalPages = Math.ceil(data.length / perPage);
  const [dataToDisplay, setDataToDisplay] = useState(data.slice(0, perPage));
  const [currentPage, setCurrentPage] = useState(1);

  const update = (start = 0, end = perPage, page = 1) => {
    setDataToDisplay(data.slice(start, end));
    setCurrentPage(page);
  };

  useEffect(() => {
    update();
  }, [data]);

  const handlePageChange = (event, page) => {
    const dataStart = perPage * (page - 1);
    const dataEnd = dataStart + perPage;
    update(dataStart, dataEnd, page);
  };

  return (
    <div>
      <div className={classes.data}>{render(dataToDisplay)}</div>
      {dataToDisplay.length > 0 && (
        <div className={classes.controls}>
          <Pagination
            color="primary"
            size={isXsBreakpoint ? "small" : "medium"}
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
}

PaginationWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  perPage: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired,
};

export default PaginationWrapper;
