import { useReducer } from "react";

function useSieve(initialState) {
  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "UPDATE_FILTER": {
        const filter = { ...state.filter, ...payload };
        return {
          ...state,
          filter,
        };
      }
      case "UPDATE_SORT": {
        return {
          ...state,
          sort: {
            ...payload,
          },
        };
      }
      case "RESET": {
        return { ...initialState };
      }
      default:
        return state;
    }
  };

  const [sieve, dispatch] = useReducer(reducer, initialState);

  const updateFilter = (payload) => {
    dispatch({ type: "UPDATE_FILTER", payload });
  };

  const updateSort = (payload) => {
    dispatch({ type: "UPDATE_SORT", payload });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  return { sieve, updateFilter, updateSort, reset };
}

export default useSieve;
