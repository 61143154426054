import { useState } from "react";

function useDebounce(func, delay = 500) {
  const [id, setId] = useState(null);

  const debouncedFunc = (...args) => {
    if (id) {
      clearTimeout(id);
    }

    setId(
      setTimeout(() => {
        func(...args);
      }, delay)
    );
  };

  return debouncedFunc;
}

export default useDebounce;
